import {
  getByStoreId,
  getClosest,
  getOperationalHour
} from 'services/store/storeService'
// eslint-disable-next-line import/no-cycle
import { updateStoreMap } from 'actions/storeMap/storeMapAction'
import {
  FETCH_STORE,
  RECEIVE_CLOSEST_STORE,
  RECEIVE_OPERATIONAL_HOUR_STORE,
  SET_CURRENT_STORE,
  FAILED_STORE
} from '../types'

const fetch = () => {
  return {
    type: FETCH_STORE
  }
}

const receiveClosestStore = (response) => {
  return {
    type: RECEIVE_CLOSEST_STORE,
    payload: {
      list: response && response.data && response.data.length > 0 ? response.data : [],
      meta: response && response.meta ? response.meta : []
    }
  }
}

const receiveOperationalHourStore = (response) => {
  return {
    type: RECEIVE_OPERATIONAL_HOUR_STORE,
    payload: {
      data: response && response.data,
      meta: response && response.meta ? response.meta : []
    }
  }
}

const setCurrentStore = (response) => {
  return {
    type: SET_CURRENT_STORE,
    payload: {
      data: response
    }
  }
}

const failed = (error) => {
  return {
    type: FAILED_STORE,
    payload: {
      error: typeof error === 'object' ? error.message : error
    }
  }
}

const getOperationalHourStore = (storeId) => async (dispatch) => {
  dispatch(fetch())
  const response = await getOperationalHour(storeId)
  if (response && response.success && response.data) {
    dispatch(receiveOperationalHourStore(response))
  } else {
    dispatch(failed(response))
  }
}

const getClosestStore = (latitude, longitude) => async (dispatch) => {
  dispatch(fetch())
  const response = await getClosest(latitude, longitude)
  if (response && response.success && response.data) {
    dispatch(receiveClosestStore(response, response.meta))

    if (response.data.length > 0) {
      dispatch(setCurrentStore(response.data[0]))
      dispatch(updateStoreMap(response.data[0].id))
    }
  } else {
    dispatch(failed(response))
  }
}

const setCurrentStoreById = (id) => async (dispatch) => {
  dispatch(fetch())
  const response = await getByStoreId(id)
  const storeId = id
  if (response && response.success) {
    if (response.data && response.data.id) {
      dispatch(setCurrentStore(response.data))
      dispatch(getOperationalHourStore(storeId))
    } else {
      dispatch(setCurrentStore(undefined))
    }
  } else {
    dispatch(failed(response))
  }
}

export {
  getClosestStore,
  getOperationalHourStore,
  setCurrentStoreById,
  receiveClosestStore,
  setCurrentStore
}
