import {
  getUserAdresses,
  getUserAdressesId,
  postUserMainAddress,
  deleteUserAddress,
  putUserAddress,
  postAddUserAddress,
  getAdresseslatLng,
  userAddressGetDineIn,
  userAddressAddDineIn,
  userAddressUpdateDineIn,
  getMapsAddressList,
  getMapsAddressDetail
} from 'services/selectAddress/selectAddressService'
import { apiStoreMapUserUpdate } from 'services/storemap/storeMapService'
import { getByStoreId, getTableById, getClosest } from 'services/store/storeService'
import { updateState as updateStateHome } from 'actions/home/homeAction'
import { receiveClosestStore, setCurrentStore, setCurrentStoreById } from 'actions/store/storeAction'
import { update as updateStoreMapState, setDineInStatus } from 'actions/storeMap/storeMapAction'
import { getMyData } from 'services/auth/authService'

import { get as getHomeExploreStore } from 'actions/home/homeExploreStoreAction'
import { get as getHomeWeeklyTrend } from 'actions/home/homeWeeklyTrendAction'
import { get as getHomeBuyAgain } from 'actions/home/homeBuyAgainAction'
import { get as getHomeBrandList } from 'actions/home/homeBrandListAction'
import { get as getHomeCategoryList } from 'actions/home/homeCategoryListAction'
import { get as getProductSignature, updateState as updateStateHomeProductSignature } from 'actions/home/homeProductSignatureAction'
import { getShoppingCart, shoppingCartAdd } from 'actions/shoppingCart/shoppingCartAction'

import {
  FETCH_SELECT_ADDRESS,
  RECEIVE_SELECT_ADDRESS,
  RECEIVE_ITEM_SELECT_ADDRESS,
  UPDATE_LATLNG_ITEM_SELECT_ADDRESS,
  CLEAR_ITEM_SELECT_ADDRESS,
  SUCCESS_SELECT_ADDRESS,
  SET_CURRENT_LNGLAT_SELECT_ADDRESS,
  FAILED_SELECT_ADDRESS,
  SET_MAIN_ADDRESS,
  GET_DINE_IN_ADDRESS,
  ADD_DINE_IN_ADDRESS,
  UPDATE_DINE_IN_ADDRESS,
  UPDATE_STATE_SELECT_ADDRESS,
  UPDATE_STATE_ADDRESS_SUGGESTION,
  RECEIVE_ADDRESS_SUGGESTION_LIST,
  RECEIVE_ADDRESS_DETAIL
} from '../types'

const fetch = () => {
  return {
    type: FETCH_SELECT_ADDRESS
  }
}

const receive = (list, meta = {}) => {
  return {
    type: RECEIVE_SELECT_ADDRESS,
    payload: {
      list,
      meta
    }
  }
}

const updateState = (payload) => {
  return {
    type: UPDATE_STATE_SELECT_ADDRESS,
    payload
  }
}

const updateSuggestion = (payload) => {
  return {
    type: UPDATE_STATE_ADDRESS_SUGGESTION,
    payload
  }
}

const receiveItem = (data, meta = {}) => {
  return {
    type: RECEIVE_ITEM_SELECT_ADDRESS,
    payload: {
      data,
      meta
    }
  }
}

const receiveSuggestionList = (data, meta = {}) => {
  return {
    type: RECEIVE_ADDRESS_SUGGESTION_LIST,
    payload: {
      data,
      meta
    }
  }
}

const receiveAddressDetail = (data, meta = {}) => {
  return {
    type: RECEIVE_ADDRESS_DETAIL,
    payload: {
      data,
      meta
    }
  }
}

const clearReceiveItem = () => {
  return {
    type: CLEAR_ITEM_SELECT_ADDRESS,
    payload: {}
  }
}

const setMainAddress = (mainAddress, meta = {}) => {
  return {
    type: SET_MAIN_ADDRESS,
    payload: {
      mainAddress,
      meta
    }
  }
}

const success = (data, meta) => {
  return {
    type: SUCCESS_SELECT_ADDRESS,
    payload: {
      data,
      meta
    }
  }
}

const setLatLng = (data) => {
  return {
    type: SET_CURRENT_LNGLAT_SELECT_ADDRESS,
    payload: {
      data
    }
  }
}

const receiveAddressDineIn = (data) => {
  return {
    type: GET_DINE_IN_ADDRESS,
    payload: {
      data
    }
  }
}

const addAddressDineIn = (data) => {
  return {
    type: ADD_DINE_IN_ADDRESS,
    payload: {
      data
    }
  }
}

const updateAddressDineIn = (data) => {
  return {
    type: UPDATE_DINE_IN_ADDRESS,
    payload: {
      data
    }
  }
}

const setLatLngCurrentItem = (latitude, longitude) => {
  const data = {
    latitude,
    longitude
  }
  return {
    type: UPDATE_LATLNG_ITEM_SELECT_ADDRESS,
    payload: {
      data
    }
  }
}

const failed = (error) => {
  return {
    type: FAILED_SELECT_ADDRESS,
    payload: {
      error: typeof error === 'object' ? error.message : error
    }
  }
}

const get = () => async (dispatch) => {
  dispatch(fetch())
  const response = await getUserAdresses()
  if (response && response.success && response.data && response.data.length > 0) {
    const filteredMainAddress = response.data.filter((filtered) => filtered.addressMain)
    let mainAddress = {}
    if (filteredMainAddress && filteredMainAddress.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      mainAddress = filteredMainAddress[0]
    } else {
      // eslint-disable-next-line prefer-destructuring
      mainAddress = response.data[0]
    }
    dispatch(receive(response.data, response.meta))
    dispatch(setMainAddress(mainAddress, response.meta))
  } else {
    dispatch(failed(response))
  }
}

const getById = (id) => async (dispatch) => {
  dispatch(fetch())
  const response = await getUserAdressesId(id)
  if (response && response.success && response.data) {
    dispatch(clearReceiveItem())
    dispatch(receiveItem(response.data, response.meta))
    return response.data
  }
  dispatch(failed(response))
}

const getAddressSuggestionList = (data) => async (dispatch) => {
  dispatch(fetch())
  const response = await getMapsAddressList(data)
  if (response && response.success && response.predictions) {
    dispatch(receiveSuggestionList(response.predictions, response.status))
    return response.predictions
  }
  dispatch(failed(response))
}

const getAddressDetail = (data) => async (dispatch) => {
  dispatch(fetch())
  const response = await getMapsAddressDetail(data)
  if (response && response.success && response.location) {
    dispatch(receiveAddressDetail(response.location, response.meta))
    return response.location
  }
  dispatch(failed(response))
}

const PutUserAddress = (data) => async (dispatch) => {
  dispatch(fetch())
  const response = await putUserAddress(data.id, data)
  if (response && response.success) {
    dispatch(success(response.data, response.meta))
    return response
  }

  dispatch(failed(response))
  return response
}

const PostAddUserAddress = (data) => async (dispatch) => {
  dispatch(fetch())
  const response = await postAddUserAddress(data)
  if (response && response.success) {
    dispatch(success(response.data, response.meta))
  }

  dispatch(failed(response))
  return response
}

const PostUserMainAddress = (data, onCloseAllModal) => async (dispatch) => {
  dispatch(fetch())
  const response = await postUserMainAddress(data.id, data)
  if (response && response.success) {
    dispatch(success(response.data, response.meta))
    onCloseAllModal()
    dispatch(get())
    return response
  }
  dispatch(failed(response))
}

const DeleteUserAddress = (data, onCloseAllModal) => async (dispatch) => {
  dispatch(fetch())
  const response = await deleteUserAddress(data.id)
  if (response && response.success) {
    dispatch(success(response.data, response.meta))
    onCloseAllModal()
    dispatch(get())
    return response
  }
  dispatch(failed(response))
}

const setCurrentLatLng = (currentlng, currentlat) => async (dispatch) => {
  const data = {
    currentlng,
    currentlat
  }
  dispatch(setLatLng(data))
  dispatch(setLatLngCurrentItem(currentlat, currentlng))
}

const getAddressLatLng = (currentlng, currentlat) => async () => {
  // dispatch(fetch())
  const response = await getAdresseslatLng(currentlat, currentlng)
  if (response && response.success && response.results.length > 0) {
    // dispatch(receiveAddressRawItem(response.results[0]))
    return response.results[0]
  }
  // dispatch(failed(response))
}

const removeDineIn = () => async (dispatch) => {
  dispatch(receiveAddressDineIn({}))
}

const addAddressDineIn_ = (data) => async (dispatch) => {
  dispatch(fetch())
  const response = await userAddressAddDineIn(data)
  if (response && response.success) {
    dispatch(addAddressDineIn(response))
  }
  dispatch(failed(response))
}

const updateAddressDineIn_ = (data) => async (dispatch) => {
  dispatch(fetch())
  const response = await userAddressUpdateDineIn(data)
  if (response && response.success) {
    dispatch(updateAddressDineIn(response.results))
  }
  dispatch(failed(response))
}

const mapDataAddressDineIn = (resultStore, tableData, idUserAddress, Mode) => async (dispatch) => {
  const userData = await getMyData()
  if (userData && userData.success) {
    // create dine in address apabila tidak ada
    const dataDineInAddress = {
      id: idUserAddress,
      tableId: tableData.id,
      dineInStatus: 1,
      userName: `${userData.data.firstName} ${userData.data.lastName}`,
      address: resultStore.address,
      longitude: resultStore.longitude,
      latitude: resultStore.latitude,
      tableName: tableData.name,
      phone: userData.data.phone
    }

    if (Mode === 'UPDATE') { dispatch(updateAddressDineIn_(dataDineInAddress)) } else if (Mode === 'CREATE') { dispatch(addAddressDineIn_(dataDineInAddress)) }
    dispatch(receiveAddressDineIn(dataDineInAddress))
  }
}

const getAddressDineIn = (resultStore, tableData) => async (dispatch) => {
  dispatch(fetch())
  const response = await userAddressGetDineIn()
  if (response && response.success && response.data.length > 0) {
    const idUserAddress = response.data[0].id
    dispatch(mapDataAddressDineIn(resultStore, tableData, idUserAddress, 'UPDATE'))
  } else if (response.message === 'Data Not Found.') {
    dispatch(mapDataAddressDineIn(resultStore, tableData, undefined, 'CREATE'))
  }
  dispatch(failed(response))
}

const updateStoreMapHome = (storeId, firstLoad) => async (dispatch) => {
  dispatch(fetch())
  const response = await apiStoreMapUserUpdate(storeId)
  if (response && response.success && response.data) {
    dispatch(updateStoreMapState(storeId))

    dispatch(shoppingCartAdd({}))
    dispatch(getHomeCategoryList({}))
    dispatch(getHomeBrandList({}))
    dispatch(getHomeExploreStore({}))
    if (firstLoad !== 1) {
      dispatch(updateStateHomeProductSignature({
        list: []
      }))
      dispatch(getHomeWeeklyTrend({}))
      dispatch(getProductSignature({}))
    }
    dispatch(getHomeBuyAgain({}))
    dispatch(getShoppingCart())
  } else {
    dispatch(failed(response))
    dispatch(getHomeCategoryList({}))
    dispatch(getHomeBrandList({}))
    dispatch(getHomeExploreStore({}))
    dispatch(getHomeWeeklyTrend({}))
    dispatch(getShoppingCart())
  }
}

const updateStoreMapCart = (storeId) => async (dispatch) => {
  dispatch(fetch())
  const response = await apiStoreMapUserUpdate(storeId)
  if (response && response.success && response.data) {
    dispatch(updateStoreMapState(storeId))
  } else {
    dispatch(failed(response))
  }
}

const getClosestStoreHome = (latitude, longitude, firstLoad) => async (dispatch) => {
  dispatch(fetch())
  const response = await getClosest(latitude, longitude)
  if (response && response.success && response.data) {
    dispatch(receiveClosestStore(response, response.meta))

    if (response.data.length > 0) {
      dispatch(setCurrentStore(response.data[0]))
      dispatch(setCurrentStoreById(response.data[0].id))
    }
    dispatch(updateStoreMapHome(3, firstLoad))
  } else {
    dispatch(failed(response))
  }
}

const getCurrentLocation = (firstLoad) => async (dispatch) => {
  navigator.geolocation.getCurrentPosition((position) => {
    // jika user tidak ada store terdekat
    if (position && position.coords && position.coords.latitude && position.coords.longitude) {
      dispatch(getClosestStoreHome(position.coords.latitude, position.coords.longitude))
    }
  }, () => {
    dispatch(updateStoreMapHome(0, firstLoad))
  }, {
    enableHighAccuracy: true
  })
}

const mapDataDelivery = (payload) => async (dispatch) => {
  const { firstLoad } = payload
  dispatch(fetch())
  const response = await getUserAdresses()
  if (response && response.success && response.data && response.data.length > 0) {
    const filteredMainAddress = response.data.filter((filtered) => filtered.addressMain)

    let mainAddress = {}
    if (filteredMainAddress && filteredMainAddress.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      mainAddress = filteredMainAddress[0]
    } else {
      // eslint-disable-next-line prefer-destructuring
      mainAddress = response.data[0]
    }
    dispatch(receive(response.data, response.meta))
    dispatch(setMainAddress(mainAddress, response.meta))

    if (mainAddress.latitude && mainAddress.longitude) {
      dispatch(getClosestStoreHome(mainAddress.latitude, mainAddress.longitude, firstLoad))
    } else {
      dispatch(getCurrentLocation(firstLoad))
    }
  } else if (response && response.success && response.data && response.data.length === 0) {
    dispatch(getCurrentLocation(firstLoad))
  } else {
    dispatch(getHomeCategoryList({}))
    dispatch(getHomeBrandList({}))
    dispatch(getHomeExploreStore({}))
    dispatch(getHomeWeeklyTrend({}))
    dispatch(getProductSignature({}))
    dispatch(getHomeBuyAgain({}))
  }
}

const cancelDineIn = (message) => async (dispatch) => {
  window.sessionStorage.removeItem('DINE_IN')
  if (message) {
    dispatch(updateStateHome({
      errorMessage: message
    }))
  }
  dispatch(mapDataDelivery(mapDataDelivery({ firstLoad: 0 })))
}

const mapDataDineIn = (dineIn) => async (dispatch) => {
  if (!dineIn.tableId || !dineIn.storeId) {
    dispatch(cancelDineIn('QR Code Salah'))
    return
  }

  const storeId = parseInt(dineIn.storeId, 10)
  const tableId = parseInt(dineIn.tableId, 10)

  const allStore = await getByStoreId(storeId)

  if (allStore && allStore.success && allStore.data && allStore.data.id) {
    const resultStore = allStore.data
    if (resultStore) {
      const tableData = await getTableById(tableId)
      if (tableData && tableData.success && tableData.data) {
        dispatch(updateStoreMapHome(storeId))
        dispatch(setCurrentStoreById(storeId))
        dispatch(getAddressDineIn(allStore.data, tableData.data))
        dispatch(setDineInStatus(true))
      } else {
        dispatch(cancelDineIn('Meja tidak ada'))
      }
    } else {
      dispatch(cancelDineIn('Store tidak ada'))
      dispatch(getHomeCategoryList({}))
      dispatch(getHomeBrandList({}))
      dispatch(getHomeExploreStore({}))
      dispatch(getHomeWeeklyTrend({}))
      dispatch(getShoppingCart())
    }
  } else {
    dispatch(getHomeCategoryList({}))
    dispatch(getHomeBrandList({}))
    dispatch(getHomeExploreStore({}))
    dispatch(getHomeWeeklyTrend({}))
    dispatch(getShoppingCart())
  }
}

const mapDataDineInCart = (dineIn) => async (dispatch) => {
  if (!dineIn.tableId || !dineIn.storeId) {
    dispatch(cancelDineIn('QR Code Salah'))
    return
  }

  const storeId = parseInt(dineIn.storeId, 10)
  const tableId = parseInt(dineIn.tableId, 10)

  const allStore = await getByStoreId(storeId)

  if (allStore && allStore.success && allStore.data && allStore.data.id) {
    const resultStore = allStore.data
    if (resultStore) {
      const tableData = await getTableById(tableId)
      if (tableData && tableData.success && tableData.data) {
        dispatch(updateStoreMapCart(storeId))
        dispatch(setCurrentStoreById(storeId))
        dispatch(getAddressDineIn(resultStore, tableData.data))
        dispatch(setDineInStatus(true))
      } else {
        dispatch(cancelDineIn('Meja tidak ada'))
      }
    } else {
      dispatch(cancelDineIn('Store tidak ada'))
    }
  }
}

export {
  get,
  getById,
  DeleteUserAddress,
  PutUserAddress,
  PostUserMainAddress,
  PostAddUserAddress,
  setCurrentLatLng,
  getAddressLatLng,
  getAddressDineIn,
  updateAddressDineIn_,
  addAddressDineIn_,
  setMainAddress,
  updateState,
  mapDataDelivery,
  removeDineIn,
  mapDataDineIn,
  mapDataDineInCart,
  updateSuggestion,
  getAddressSuggestionList,
  getAddressDetail
}
