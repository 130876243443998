import {
  getBrandList
} from 'services/home/homeService'
import {
  FETCH_HOME_BRAND_LIST,
  RECEIVE_HOME_BRAND_LIST,
  FAILED_HOME_BRAND_LIST
} from '../types'

const fetch = () => {
  return {
    type: FETCH_HOME_BRAND_LIST
  }
}

const receive = (list, meta = {}) => {
  return {
    type: RECEIVE_HOME_BRAND_LIST,
    payload: {
      list,
      meta
    }
  }
}

const failed = (error) => {
  return {
    type: FAILED_HOME_BRAND_LIST,
    payload: {
      error: typeof error === 'object' ? error.message : error
    }
  }
}

const get = (params) => async (dispatch) => {
  dispatch(fetch())
  const response = await getBrandList({
    ...params,
    page: 1
  })
  if (response && response.success && response.data && response.data.length > 0) {
    dispatch(receive(response.data, response.meta))
  } else {
    dispatch(failed(response))
  }
}

export {
  get
}
