import request from 'utils/request'
import { getUserToken } from 'utils/storage'

const getUserAdresses = async () => {
  const token = await getUserToken()
  if (token) {
    return request({
      url: '/user-address/get',
      auth: token,
      method: 'get'
    })
  }
  return ({
    success: 'failed',
    message: 'Unauthorized',
    meta: {
      message: 'Unauthorized'
    }
  })
}

const getUserAdressesId = async (id) => {
  const token = await getUserToken()
  return request({
    url: `/user-address/get/${id}`,
    auth: token,
    method: 'get'
  })
}

const postUserMainAddress = async (id, data) => {
  const token = await getUserToken()
  return request({
    url: `/user-address/main-address/${id}`,
    data: {
      ...data
    },
    auth: token,
    method: 'post'
  })
}

const postAddUserAddress = async (data) => {
  const token = await getUserToken()
  return request({
    url: '/user-address/add',
    data,
    auth: token,
    method: 'post'
  })
}

const putUserAddress = async (id, data) => {
  const token = await getUserToken()
  return request({
    url: `/user-address/edit/${id}`,
    data,
    auth: token,
    method: 'put'
  })
}

const deleteUserAddress = async (id) => {
  const token = await getUserToken()
  return request({
    url: `/user-address/delete/${id}`,
    auth: token,
    method: 'post'
  })
}

const getAdresseslatLng = async (latitude, longitude) => {
  const token = await getUserToken()
  return request({
    url: `/maps/api/geocode/json?latlng=${latitude},${longitude}`,
    auth: token,
    method: 'post'
  })
}

const getMapsAddressList = async (data) => {
  const token = await getUserToken()
  return request({
    url: '/maps/api/auto-complete/json',
    params: {
      device: 'web',
      search: data.search,
      sessionToken: data.sessionToken
    },
    auth: token,
    method: 'post'
  })
}

const getMapsAddressDetail = async (data) => {
  const token = await getUserToken()
  return request({
    url: '/maps/api/detail/json',
    params: {
      device: 'web',
      placeId: data.placeId,
      sessionToken: data.sessionToken
    },
    auth: token,
    method: 'post'
  })
}

const userAddressGetDineIn = async () => {
  const token = await getUserToken()
  return request({
    url: '/user-address/get-dine-in',
    auth: token,
    method: 'get'
  })
}

const userAddressAddDineIn = async (data) => {
  const token = await getUserToken()
  return request({
    url: '/user-address/add-dine-in',
    data,
    auth: token,
    method: 'post'
  })
}

const userAddressUpdateDineIn = async (data) => {
  const token = await getUserToken()
  return request({
    url: '/user-address/edit-dine-in',
    data,
    auth: token,
    method: 'put'
  })
}

export {
  getUserAdresses,
  putUserAddress,
  deleteUserAddress,
  postUserMainAddress,
  postAddUserAddress,
  getUserAdressesId,
  getAdresseslatLng,
  userAddressGetDineIn,
  userAddressAddDineIn,
  userAddressUpdateDineIn,
  getMapsAddressList,
  getMapsAddressDetail
}
