import request from 'utils/request'

const apiStoreMapUser = async () => {
  return request({
    url: '/store-map-user/get',
    auth: false,
    method: 'get'
  })
}

const apiStoreMapUserAdd = async (storeId) => {
  return request({
    url: `/store-map-user/add?storeId=${storeId}`,
    auth: true,
    method: 'post'
  })
}

const apiStoreMapUserUpdate = async (storeId) => {
  return request({
    url: '/store-map-user/edit?device=web',
    auth: true,
    data: {
      storeId
    },
    method: 'put'
  })
}

export {
  apiStoreMapUser,
  apiStoreMapUserAdd,
  apiStoreMapUserUpdate
}
