import {
  apiStoreMapUser,
  apiStoreMapUserAdd,
  apiStoreMapUserUpdate
} from 'services/storemap/storeMapService'
import { getUserToken } from 'utils/storage'
// eslint-disable-next-line import/no-cycle
import {
  FETCH_STORE_MAP,
  RECEIVE_STORE_MAP,
  ADD_STORE_MAP,
  UPDATE_STORE_MAP,
  SET_DINE_IN_STATUS,
  FAILED_STORE_MAP
} from '../types'

const fetch = () => {
  return {
    type: FETCH_STORE_MAP
  }
}

const receive = (response) => {
  return {
    type: RECEIVE_STORE_MAP,
    payload: {
      data: response && response.data,
      meta: response && response.meta ? response.meta : []
    }
  }
}

const add = (response) => {
  return {
    type: ADD_STORE_MAP,
    payload: {
      list: response && response.data && response.data.length > 0 ? response.data : [],
      meta: response && response.meta ? response.meta : []
    }
  }
}

const update = (storeId) => {
  return {
    type: UPDATE_STORE_MAP,
    payload: {
      data: storeId
    }
  }
}

const setDineInStatus = (dineInStatus) => {
  return {
    type: SET_DINE_IN_STATUS,
    payload: {
      data: dineInStatus
    }
  }
}

const failed = (error) => {
  return {
    type: FAILED_STORE_MAP,
    payload: {
      error: typeof error === 'object' ? error.message : error
    }
  }
}

const getStoreMap = () => async (dispatch) => {
  const token = await getUserToken()
  if (!token) {
    return
  }
  dispatch(fetch())
  const response = await apiStoreMapUser()
  if (response && response.success && response.data) {
    dispatch(receive(response))
  } else {
    dispatch(failed(response))
  }
}

const addStoreMap = () => async (dispatch) => {
  dispatch(fetch())
  const response = await apiStoreMapUserAdd()
  if (response && response.success && response.data) {
    dispatch(add(response))
  } else {
    dispatch(failed(response))
  }
}

const updateStoreMap = (storeId) => async (dispatch) => {
  dispatch(fetch())
  const response = await apiStoreMapUserUpdate(storeId)
  if (response && response.success && response.data) {
    dispatch(update(storeId))
  } else {
    dispatch(failed(response))
  }
}

export {
  getStoreMap,
  addStoreMap,
  updateStoreMap,
  update,
  setDineInStatus
}
