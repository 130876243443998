import request from 'utils/request'

const getByStoreId = async (storeId) => {
  return request({
    url: `/store/get/${storeId}`,
    auth: false,
    method: 'get'
  })
}

const getClosest = async (latitude, longitude) => {
  return request({
    url: `/store/get-closest?latitude=${latitude}&longitude=${longitude}`,
    auth: false,
    method: 'get'
  })
}

const getOperationalHour = async (storeId) => {
  return request({
    url: `/store/get-hour?storeId=${storeId}&device=web`,
    auth: false,
    method: 'get'
  })
}

const getTableById = async (tableId) => {
  return request({
    url: `/table/get?id=${tableId}&device=web`,
    auth: false,
    method: 'get'
  })
}

export {
  getByStoreId,
  getClosest,
  getOperationalHour,
  getTableById
}
