import {
  getHome
} from 'services/home/homeService'
import pagination from 'utils/pagination'
import {
  FETCH_HOME,
  RECEIVE_HOME,
  FAILED_HOME,
  UPDATE_STATE_HOME
} from '../types'

const fetch = () => {
  return {
    type: FETCH_HOME
  }
}

const receive = (list, meta = {}) => {
  return {
    type: RECEIVE_HOME,
    payload: {
      list,
      meta
    }
  }
}

const failed = (error) => {
  return {
    type: FAILED_HOME,
    payload: {
      error: typeof error === 'object' ? error.message : error
    }
  }
}

const updateState = (payload) => {
  return {
    type: UPDATE_STATE_HOME,
    payload
  }
}

const get = (params) => async (dispatch) => {
  const dataset = await pagination({
    pageSize: 10,
    fetch: () => dispatch(fetch()),
    receive: (data) => {
      dispatch(receive(data))
    },
    success: () => {
      dispatch(updateState({
        loading: false
      }))
    },
    failed: ({ message }) => dispatch(failed(message)),
    api: (pageOffset) => getHome({
      ...params,
      page: pageOffset + 1
    })
  })
  dataset.setReadOffset(0)

  dispatch(updateState({
    dataSet: dataset
  }))
}

const changeFilter = (params) => async (dispatch, getState) => {
  const { filter } = getState().homeStore
  dispatch(updateState({
    filter: {
      ...filter,
      ...params
    }
  }))
  dispatch(get({
    ...filter,
    ...params
  }))
}

export {
  changeFilter,
  updateState
}
